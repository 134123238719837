<div class="fuse-drawer-content flex flex-col shadow-2xl"
     [ngClass]="{ 'w-half' : sizeType === 'half', 'w-large': sizeType === 'large' }">
    @if (showUpBand) {
        <span class="up-band w-full" [ngClass]="{'h-[40px]': showCloseButton && !isScreenSmall}">
            @if (showCloseButton && !isScreenSmall) {
                <div class="flex items-end justify-end w-full ">
                    <button mat-icon-button (click)="close()">
                        <mat-icon
                             class="dark:text-white"
                            svgIcon="heroicons_outline:x"></mat-icon>
                    </button>
                </div>
            }
        </span>
    } @else {
        @if (showCloseButton && !isScreenSmall) {
            <div class="close-button absolute top-0 right-0 p-4">
                <button mat-icon-button (click)="close()">
                    <mat-icon svgIcon="heroicons_outline:x"></mat-icon>
                </button>
            </div>
        }
    }

    <ng-content></ng-content>
</div>
